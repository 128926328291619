exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-template-pages-about-tsx": () => import("./../../../src/template-pages/About.tsx" /* webpackChunkName: "component---src-template-pages-about-tsx" */),
  "component---src-template-pages-blog-detail-tsx": () => import("./../../../src/template-pages/BlogDetail.tsx" /* webpackChunkName: "component---src-template-pages-blog-detail-tsx" */),
  "component---src-template-pages-blog-index-tsx": () => import("./../../../src/template-pages/BlogIndex.tsx" /* webpackChunkName: "component---src-template-pages-blog-index-tsx" */),
  "component---src-template-pages-contact-tsx": () => import("./../../../src/template-pages/Contact.tsx" /* webpackChunkName: "component---src-template-pages-contact-tsx" */),
  "component---src-template-pages-home-tsx": () => import("./../../../src/template-pages/Home.tsx" /* webpackChunkName: "component---src-template-pages-home-tsx" */),
  "component---src-template-pages-portfolio-detail-tsx": () => import("./../../../src/template-pages/PortfolioDetail.tsx" /* webpackChunkName: "component---src-template-pages-portfolio-detail-tsx" */),
  "component---src-template-pages-portfolio-index-tsx": () => import("./../../../src/template-pages/PortfolioIndex.tsx" /* webpackChunkName: "component---src-template-pages-portfolio-index-tsx" */),
  "component---src-template-pages-press-index-tsx": () => import("./../../../src/template-pages/PressIndex.tsx" /* webpackChunkName: "component---src-template-pages-press-index-tsx" */),
  "component---src-template-pages-privacy-policy-tsx": () => import("./../../../src/template-pages/PrivacyPolicy.tsx" /* webpackChunkName: "component---src-template-pages-privacy-policy-tsx" */),
  "component---src-template-pages-product-detail-tsx": () => import("./../../../src/template-pages/ProductDetail.tsx" /* webpackChunkName: "component---src-template-pages-product-detail-tsx" */),
  "component---src-template-pages-product-index-tsx": () => import("./../../../src/template-pages/ProductIndex.tsx" /* webpackChunkName: "component---src-template-pages-product-index-tsx" */),
  "component---src-template-pages-studio-tsx": () => import("./../../../src/template-pages/Studio.tsx" /* webpackChunkName: "component---src-template-pages-studio-tsx" */)
}

